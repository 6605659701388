// New Colors
$grayPrimary: #213057;
$graySecondary: #192543;
$brandPrimary: #537bdf;
$brandSecondary: #4262b2;
$brandLight: #d4dbee;
$greenPrimary: #00977c;
$greenSecondary: #66c1b0;
$greenLight: #b3e0d8;
$eggshellPrimary: #e9e6dc;
$eggshellSecondary: #a6a39b;
$eggshellLight: #f8f8f4;
$orangePrimary: #eca613;
$orangeSecondary: #bd8510;
$orangeLight: #f5ca71;
$purplePrimary: #8e1c5a;
$purpleSecondary: #551136;
$purpleLight: #bb779c;
$redPrimary: #ea4858;
$redSecondary: #8c2b35;
$redLight: #ffc6cc;
$black: #000000;
$white: #ffffff;

// Old Colors
// lionstep blue
$bondi-blue-100: #e9f4f7;
$bondi-blue-200: #c8e4ec;
$bondi-blue-300: #a7d3e0;
$bondi-blue-400: #65b3c9;
$bondi-blue-500: #2392b2;
$bondi-blue-600: #2083a0;
$bondi-blue-700: #15586b;
$bondi-blue-800: #104250;
$bondi-blue-900: #0b2c35;

// lionstep green
$apple-100: #f0f7ec;
$apple-200: #daeccf;
$apple-300: #c4e1b2;
$apple-400: #98ca79;
$apple-500: #6cb33f;
$apple-600: #61a139;
$apple-700: #416b26;
$apple-800: #31511c;
$apple-900: #203613;

// lionstep gray
$loblolly-100: #f8f9fa;
$loblolly-200: #edf1f2;
$loblolly-300: #e3e8eb;
$loblolly-400: #cdd7db;
$loblolly-500: #b8c6cc;
$loblolly-600: #a6b2b8;
$loblolly-700: #6e777a;
$loblolly-800: #53595c;
$loblolly-900: #373b3d;

$text-light: #94a7af;
$text-dark: #36464d;

// Break points
