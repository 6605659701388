@import '../styles/variables';

.main {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 72rem;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: $text-dark;
  font-size: 18px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.message {
  margin-top: 0.5rem;
}

.link {
  text-decoration-line: underline;
  color: $bondi-blue-500;
}

.farewell {
  margin-top: 2rem;
}

.logo {
  margin-top: 0.5rem;
  height: 2.5rem;
}
